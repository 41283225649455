import { Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import React from "react";
import ListItemText from '@mui/material/ListItemText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CheckCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import './footer.css';
import axios from "axios";

const Overview = (data) => {
    const [skills, setskills] = useState([])
    const course = async (data) => {
    
        await axios({
          url: "https://web.deshbhagatonline.com/courseIELTS/allCourses",
          method: "post",
        }).then((response) => {
          console.log(response.data.Items)
          let arr = []
          for (let i in response.data.Items) {
            if (response.data.Items[i].courseId == data){
              arr.push(response.data.Items[i])
               }
    
          }
          console.log(arr[0].skills)
          setskills(arr[0].skills)
        })
      }
      useEffect(()=>{
        course(data.data)
      },[])
   
    
    const features = [
        { name: "certificate from IIM Indore" }, { name: "Delivery mix of self paced learning and live sessions" }, { name: "Capstone projects mentored by IIM Indore faculty" }, { name: "Executive Education Alumni status of llM Indore" }, { name: "Online Self Paced content by IIM Indore" }, { name: "3-day campus immersion at the IIM Indore campus, at additional cost" }, { name: "Masterclasses by IIM Indore faculty and live classes by Simplilearn Instructors" }
    ]
    return (
        <Grid className="ye" container sx={{ bgcolor: '#f8fbff', paddingTop: '30px', paddingBottom: '40px',padding:'1%' }}>
            <Grid item lg={1}></Grid>
            <Grid item xs={12} lg={10}>
                {/* <Typography sx={{ color: '#272c37', fontWeight: '400', fontFamily: "Roboto", fontSize: '32px', marginTop: '56px' }}>Course Overview</Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#51565e', marginTop: '16px' }}>Move from a functional strategy role to a business strategy role with thorough knowledge of the business environment and the new fundamentals of business strategy with this general management course from IIM Indore.</Typography> */}
                <Typography className="he" sx={{ marginTop: '18px', fontSize: '24px', fontWeight: '500', color: '#272c37' }}>Skills you Gain</Typography>
                <Grid container sx={{ marginTop: '10px' }}>
                    {skills.map((item) => {

                        return (
                            <Grid item xs={12} lg={4} sx={{ marginTop: '8px', display: 'flex' }}>
                                <List sx={{paddingTop:'0%',paddingBottom:'0%'}}>
                                    <ListItem sx={{paddingLeft:'0px'}}>
                                        <ListItemIcon sx={{minWidth:'40px'}}>
                                            <CheckCircleOutlineIcon sx={{ color: '#95c8ff' }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: '#272c37' }}>{item}</ListItemText>
                                    </ListItem>
                                </List>

                            </Grid>)
                    })}
                    

                    <Grid item xs={12} lg={8} sx={{ marginTop: '32px' }}>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={1}>
            </Grid>
        </Grid>
    )
}
export default Overview;