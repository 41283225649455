import React from 'react';
import { Grid, Typography, Card} from '@mui/material';
import cardim from './Images/cardim.png';
import Business from './Images/Business.png';
import Community from './Images/Community.png';
import comp from './Images/comp.png';
import Creative from './Images/Creative.png';
import health from './Images/health.png';
import hosp from './Images/hosp.png';
import Humanities from './Images/Humanities.png';
import Literature from './Images/Literature.png';
import Trades from './Images/Trades.png';
import workforce from './Images/workforce.png';
import {Link} from "react-router-dom"
import ayur from './Images/ayur.jpg';
import './re.css';

const Categories = () => {
    const arr = [
        {
            img: Business,
            cate: 'Management',
            name: 'Management'
        },
        {
            img: health,
            cate: 'Healthcare_Science',
            name: 'Healthcare Science'
        },
        {
            img: Community,
            cate: 'Community_Services',
            name: 'Community Services'
        },
        {
            img: hosp,
            cate: 'Hospitality',
            name: 'Hospitality'
        },
        {
            img: workforce,
            cate: 'Workshop_Practice',
            name: 'workshop Practice'
        },
        {
            img: comp,
            cate: 'Computer_Science_and_IT',
            name: 'Computer Science and IT'
        },
        {
            img: Creative,
            cate: 'Creative_Industries',
            name: 'Creative Industries'
        },

        {
            img: Humanities,
            cate: 'Humanities',
            name: 'Humanities'
        },
        {
            img: Literature,
            cate: 'Communication_Skills',
            name: 'Communication Skills'
        },
        {
            img: Trades,
            cate: 'Finance_and_Accounting',
            name: 'Finance and Accounting'
        },
        {
            img: ayur,
            cate: 'Ayurveda',
            name: 'Ayurveda'
        },
        
    ]
    return (
        <Grid container sx={{ bgcolor: '#edf2f6' }}>
            <Grid item lg={1} xs={0.5}></Grid>
            <Grid item lg={10} xs={11} md={12} sm={12} padding={'2%'} marginBottom={'4%'}>
                <Typography className='p2' sx={{ fontSize: '28px', fontWeight: '500', color: '#272c37', fontFamily: 'Roboto', marginBottom: '2%', marginTop: '2%' }}>
                    Explore Categories
                </Typography>
                <Grid container sx={{}}>
                    {arr.map((row, index) => {
                        console.log(row.path)
                        return (
                            <Grid className='p3' item xs={12} lg={4} md={6} sm={6} sx={{ paddingRight: '40px', marginBotton: '30px', marginTop: '30px' }}>
                                <Link to={{
                                    pathname: `/cat`,
                                    search:`${row.cate}`
                                    //  state: {data: row.cate}
                                }}>
                                    <Card sx={{ height: '100%', width: '100%', transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', '&:hover': { boxShadow: 'rgba(31, 31, 31, 0.12) 0px 1px 6px, rgba(31, 31, 31, 0.12) 0px 1px 4px', cursor: 'pointer' } }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginRight: '10px' }}>
                                                <img src={row.img} alt="" style={{ height: '70px' }} />
                                            </div>
                                            <div style={{ padding: '20px 0' }}>
                                                <Typography sx={{ fontFamily: 'Roboto', fontSize: '18px', fontWeight: '400' }}>{row.name}</Typography>
                                            </div>
                                        </div>

                                    </Card>
                                </Link>
                            </Grid>
                        )
                    })}

                </Grid>


            </Grid>
            <Grid item lg={1} xs={0.5}></Grid>
        </Grid>
    )
}

export default Categories
