import React from 'react';
import { Card, Typography, Box, Button, rgbToHex, Grid, TableContainer, TableCell, TableHead, TableRow, TableBody, Paper, Table } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Supportcenter from './Supportcenter';
import Logistic from './logistics';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

const style = {
  
    position: 'absolute',
    marginTop:'10%',
    left: '5%',
    // transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,


};

const ComingSoon = ({ courseArray }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(courseArray.courseDetails.selling)
  
    const [dataArr, setData] = useState([])

    const center = [{"name":"Satsar Institute","Location":"Batala","address":"Satsar Institute,I/S Satsar Hospital Mann Nagar, Dera Baba Nanak Road ,Batala","phone":"9216180002","city":"Batala"},
   // {"name":"Rudra Institute","address":"Rudra Institute Chhertha, Amritsar","city":"Amritsar","phone":"9478627000"},
    {"name":"KCM Institute Of Education","address":"KCM Institute Of Education, Garden Colony","phone":"9530747063","city":"Ropar"},
    {"name":"Sangrur Institue Of Education","address":"Sangrur Institute of Education,Defence Colony, Grewal Chowk","phone":"9915629270","city":"Malerkotla"},
    {"name":"Net To Net Educational & Welfare Society","address":"Net To Net Educational & Welfare Society, Adarsh Nagar Phirni Road","phone":"9814634420","city":"Fazilka"},
    
]

    useEffect(async () => {

        await axios({
            url: "https://apsc.mrsptuonline.com/supportcentre",
            method: "POST"
        }).then((res) => {
            const arr = res.data.Items
            console.log(arr)
            setData(arr)
        }).catch(err => console.log(err))
    }, [])
  return (
    <Card sx={{ padding: '8px' }}>
      
      <Box sx={{ padding: '0 8%', marginBottom: '25px' }}>
      <img src={`https://dbucourse.s3.amazonaws.com/${courseArray.courseDetails.id}.png`} alt="" style={{ width: '100%', height: 'auto' }} />
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '1.65rem', fontWeight: '700', color: '#283034', textAlign: 'center', lineHeight: '1', margin: "10px 0px" }}>
          {courseArray.courseDetails.title} <br />
          {" "}
          {courseArray.courseDetails.price
            != undefined ? (<>
            {courseArray.courseDetails.total} </>
          ) :
            (
              <b> </b>
            )}
         
        </Typography>

        {courseArray.courseDetails.price != undefined ? (
         
          <div>
            <Button variant='contained' sx={{ margin: 'auto', backgroundColor: '#ff1f59', border: '2 px solid #ff1f59', '&:hover': { backgroundColor: 'transparent', border: '2px solid black', color: 'black' }, fontSize: '17px', padding: '20px', height: '55px', color: 'white', marginTop: '5px', marginBottom: '4px', width: '100%' }} onClick={handleOpen}>Enroll Now</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Supportcenter id={courseArray.id} />
              </Box>
            </Modal>
          </div>
          // </a>
        ) : (
          <b></b>
        )}
        <NavLink to={"/Terms"}>
          
          <Typography align="center" sx={{ color: "#254d7b", pb: "5px", pt: "4px", fontWeight: 400 }}>T & C / Guideline</Typography>
        </NavLink>
        <div style={{display:"absolute"}}>
          {/* <Typography sx={{ fontSize: '1rem', marginTop: '10px', color: '#888', fontWeight: '600', fontFamily: 'Muli,sans-serif' }}>*Original price : ₹{" "}{courseArray.courseDetails.orginal} </Typography> */}
          <div>
           <Grid> <Button variant='contained' sx={{ margin: 'auto', backgroundColor: '#ff1f59', border: '2 px solid #ff1f59', '&:hover': { backgroundColor: 'transparent', border: '2px solid black', color: 'black' }, fontSize: '17px', padding: '20px', height: '55px', color: 'white', marginTop: '5px', marginBottom: '4px', width: '100%' }} onClick={handleOpen}>Enroll Now</Button>
           </Grid>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
              <h4 >Support Center</h4>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                    <TableCell align="left">Sr.No</TableCell>
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="left">Location</TableCell>
                                                        <TableCell align="center">Address</TableCell>
                                                        <TableCell align="center">Phone Number</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {center.map((row, index) => (
                                                        <TableRow
                                                            key={row.Name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">DB-{index+1}</TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.city}</TableCell>
                                                            <TableCell align="left">{row.address}</TableCell>
                                                            <TableCell align="center">{row.phone}</TableCell>


                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
              </Box>
            </Modal>
          </div>
          <div style={{display:"flex"}}>
            
          <Typography sx={{ fontSize: '1rem', color: '#888', fontWeight: '600', fontFamily: 'Muli,sans-serif' }}>* Fee :</Typography>
          <Typography sx={{ fontSize: '1.15rem', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif', marginLeft: '10px' , marginRight:"10px" }}> &#8377; {courseArray.courseDetails.selling} </Typography>
          
         </div>

        </div><div>
        
        </div>
       
      </Box>

    </Card>
  )
}

export default ComingSoon