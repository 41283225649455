import React from 'react';
import { Typography,Grid,Box } from '@mui/material';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import './Testimonial.css';
import Art1 from './Images/Art1.png';
import Art2 from './Images/Art2.png';
import Art3 from './Images/Art3.png';
import FLAGS from './Images/FLAGS.jpg';
import FLAGS2 from './Images/FLAGS2.jpg';
import FLAGS3 from './Images/FLAGS3.jpg';
import FLAGS4 from './Images/FLAGS4.jpg';
import FLAGS5 from './Images/FLAGS5.jpg';
import FLAGS6 from './Images/FLAGS6.jpg';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);


const FlagTab = () => {
  return (
    <Grid item className='desk2' container xs={12} lg={12} md={12} sm={12} >
    <Box sx={{ width: '80%',marginBottom:'4%' }}>
    <Typography sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',fontFamily:'Roboto',marginBottom:'2%',marginTop:'2%',textAlign:'center'}}>Prestigious Universities Await You</Typography>
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
            className="swip2" style={{ height: '251px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px' }}>
            <ul className="swiper-wrapper slides">
                <SwiperSlide >
                    <Grid >

                        <div style={{}}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS2}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS3}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS4}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS5}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={FLAGS6}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
             
            </ul>
        </Swiper>

    </Box>
</Grid>
  )
}

export default FlagTab
