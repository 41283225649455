import React from 'react';
import { Typography,Grid,Box } from '@mui/material';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import './Testimonial.css';
import Artb1 from './Images/Artb1.png';
import Artb2 from './Images/Artb2.png';
import Artb3 from './Images/Artb3.png';
import Artb4 from './Images/Artb4.png';

import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const CompaniesTab = () => {
  return (
    <Grid item className='desk2' container xs={12} lg={12} md={12} sm={12} >
    <Box sx={{ width: '80%',marginBottom:'4%' }}>
    <Typography sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',fontFamily:'Roboto',marginBottom:'2%',marginTop:'2%',textAlign:'center'}}>MOU's signed with Companies</Typography>
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
            className="swip2" style={{ height: '251px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px' }}>
            <ul className="swiper-wrapper slides">
                <SwiperSlide >
                    <Grid >

                        <div style={{}}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={Artb1}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={Artb2}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={Artb3}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={Artb4}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
               
             
            </ul>
        </Swiper>

    </Box>
</Grid>
  )
}

export default CompaniesTab
