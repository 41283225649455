import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import "./Testimonial.css";
import comu from './Images/comu.png';
import test1 from './Images/test1.jpg';
import test2 from './Images/test2.jpg';
import test3 from './Images/test3.jpg';
import test4 from './Images/test4.jpg';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);


const Testimonial = () => {
    return (
        <Grid item container xs={12} lg={12} md={12} sm={12} sx={{ justifyContent: 'center', bgcolor: '#fff' }}>
            <Box sx={{ width: '80%', marginBottom: '4%' }}>
                <Typography className='we2' sx={{ fontSize: '28px', fontWeight: '500', color: '#272c37', fontFamily: 'Roboto', marginBottom: '2%', marginTop: '2%' }}>Testimonials</Typography>
                <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
                    className="swip" style={{ height: '251px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px' }}>
                    <ul className="swiper-wrapper slides">
                        <SwiperSlide >
                            <Grid >

                                <div style={{ margin: '30px' }}>
                                    {/* Quote Icon */}
                                    <div className="quote-ico">
                                        <img
                                            src={comu}
                                            style={{ height: "65px", width: '65px', opacity: '.15' }}
                                        />
                                    </div>

                                    <Typography sx={{ color: '#757575', fontSize: '1.05rem', fontWeight: '400', lineHeight: '1.4', marginTop: '-20px' }}>
                                        I had a really good experience with Desh Bhagat Global Services. The team guided me properly and it was a hassle free experience.
                                    </Typography>


                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div >
                                            <img
                                                className="img-fluid"
                                                src={test3}
                                                style={{ width: '80px', height: '80px' }}

                                            />
                                        </div>
                                        <div style={{ marginLeft: '10px', marginTop: '8px', }}>
                                            <Typography sx={{ color: "#283034", fontSize: '1.156rem', fontFamily: 'Muli,sans-serif', fontWeight: '700px' }}>Maya</Typography>
                                            <Typography sx={{ color: "#858585", fontSize: '1.1rem', fontFamily: 'Muli,sans-serif', fontWeight: '300px' }}>IELTS Plus Learner </Typography>
                                        </div>
                                    </div>

                                </div>


                            </Grid>

                        </SwiperSlide>

                        <SwiperSlide >
                            <Grid >

                                <div style={{ margin: '30px' }}>
                                    {/* Quote Icon */}
                                    <div className="quote-ico">
                                        <img
                                            src={comu}
                                            style={{ height: "65px", width: '65px', opacity: '.15' }}
                                        />
                                    </div>

                                    <Typography sx={{ color: '#757575', fontSize: '1.05rem', fontWeight: '400', lineHeight: '1.4', marginTop: '-20px' }}>
                                        Highly recommended for right Visa Guidance for CANADA. Friendly and accessible staff


                                    </Typography>


                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div >
                                            <img
                                                className="img-fluid"
                                                src={test4}
                                                style={{ width: '80px', height: '80px' }}

                                            />
                                        </div>
                                        <div style={{ marginLeft: '10px', marginTop: '8px', }}>
                                            <Typography sx={{ color: "#283034", fontSize: '1.156rem', fontFamily: 'Muli,sans-serif', fontWeight: '700px' }}>Manish</Typography>
                                            <Typography sx={{ color: "#858585", fontSize: '1.1rem', fontFamily: 'Muli,sans-serif', fontWeight: '300px' }}>IELTS Plus Learner </Typography>
                                        </div>
                                    </div>

                                </div>


                            </Grid>

                        </SwiperSlide>

                        <SwiperSlide >
                            <Grid >

                                <div style={{ margin: '30px' }}>
                                    {/* Quote Icon */}
                                    <div className="quote-ico">
                                        <img
                                            src={comu}
                                            style={{ height: "65px", width: '65px', opacity: '.15' }}
                                        />
                                    </div>

                                    <Typography sx={{ color: '#757575', fontSize: '1.05rem', fontWeight: '400', lineHeight: '1.4', marginTop: '-20px' }}>
                                        I strongly recommend Desh Bhagat Global Services to those who are planning to study abroad.
                                    </Typography>


                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div >
                                            <img
                                                className="img-fluid"
                                                src={test1}
                                                style={{ width: '80px', height: '80px' }}

                                            />
                                        </div>
                                        <div style={{ marginLeft: '10px', marginTop: '8px', }}>
                                            <Typography sx={{ color: "#283034", fontSize: '1.156rem', fontFamily: 'Muli,sans-serif', fontWeight: '700px' }}>Raj</Typography>
                                            <Typography sx={{ color: "#858585", fontSize: '1.1rem', fontFamily: 'Muli,sans-serif', fontWeight: '300px' }}>IELTS Plus Learner </Typography>
                                        </div>
                                    </div>

                                </div>


                            </Grid>

                        </SwiperSlide>

                        <SwiperSlide >
                            <Grid >

                                <div style={{ margin: '30px' }}>
                                    {/* Quote Icon */}
                                    <div className="quote-ico">
                                        <img
                                            src={comu}
                                            style={{ height: "65px", width: '65px', opacity: '.15' }}
                                        />
                                    </div>

                                    <Typography sx={{ color: '#757575', fontSize: '1.05rem', fontWeight: '400', lineHeight: '1.4', marginTop: '-20px' }}>
                                        I had a really good experience with Desh Bhagat Global Services. The team guided me properly and it was a hassle free experience.

                                    </Typography>


                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <div >
                                            <img
                                                className="img-fluid"
                                                src={test2}
                                                style={{ width: '80px', height: '80px' }}

                                            />
                                        </div>
                                        <div style={{ marginLeft: '10px', marginTop: '8px', }}>
                                            <Typography sx={{ color: "#283034", fontSize: '1.156rem', fontFamily: 'Muli,sans-serif', fontWeight: '700px' }}>Anil</Typography>
                                            <Typography sx={{ color: "#858585", fontSize: '1.1rem', fontFamily: 'Muli,sans-serif', fontWeight: '300px' }}>IELTS Plus Learner  </Typography>
                                        </div>
                                    </div>

                                </div>


                            </Grid>

                        </SwiperSlide>
                    </ul>
                </Swiper>

            </Box>
        </Grid>
    )
}

export default Testimonial
