import React from 'react';
import { Typography,Grid,Box } from '@mui/material';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css'
import logo from '../images/logo.png';
import "./Testimonial.css";

import one from './Images/one.png';
import two from './Images/two.png';
import three from './Images/three.png';
import four from './Images/four.png';
import five from './Images/five.png';
import six from './Images/six.png';
import seven from './Images/seven.png';
import eight from './Images/eight.png';
import nine from './Images/nine.png';
import ten from './Images/ten.png';
import eleven from './Images/eleven.png';
import twelve from './Images/twelve.png';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const FlagMobile = () => {
  return (
    <Grid item className='desk1' container xs={12} lg={12} md={12} sm={12} sx={{ justifyContent: 'center' ,bgcolor:'#fff'}}>
    <Box sx={{ width: '80%',marginBottom:'4%' }}>
    <Typography className='fl1' sx={{fontSize:'28px',fontWeight:'500',color:'#272c37',fontFamily:'Roboto',marginBottom:'2%',marginTop:'2%',textAlign:'center'}}>Prestigious Universities Await You</Typography>
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }} pagination={{ "clickable": true }} navigation={false}
            className="swip1" style={{ height: '251px', backgroundColor: '#edf2f6', border: '1px solid #eee', borderRadius: '4px',padding:'2%' }}>
            <ul className="swiper-wrapper slides">
                <SwiperSlide >
                    <Grid >

                        <div style={{}}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={one}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={two}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={three}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={four}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={five}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={six}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={seven}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={eight}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={nine}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={ten}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={eleven}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
                <SwiperSlide >
                    <Grid >

                        <div style={{  }}>
                            {/* Quote Icon */}
                            <div className="quote-ico">
                                <img
                                    src={twelve}
                                    style={{ height: "auto", width: '100%', }}
                                />
                            </div>

                          
                        </div>


                    </Grid>

                </SwiperSlide>
            </ul>
        </Swiper>

    </Box>
</Grid>
  )
}

export default FlagMobile
