import React, { Component, useState } from "react";
import { Container, Row, Form, Col, Image } from "react-bootstrap";

import "./footer.css";
import axios from 'axios'
import crestbelllogo from '../images/crestbelllogo.jpg';
import Button from '@mui/material/Button';
import Alert from '../components/contact/Alert'
import logonew from '../images/logonew.jpg';
import { Table, TableCell, TableRow } from "@mui/material";
import AdbIcon from '@mui/icons-material/Adb';
import AppleIcon from '@mui/icons-material/Apple';
import dbuglobal from './Images/2.png'

const Footer = (props) => {

    const [data, setData] = useState({
        email: ""
    })
    const [show, setShow] = useState(false);

    const submit = async (e) => {
        axios({
            method: "post",
            url: "https://web.deshbhagatonline.com/mailer/footer",
            data: data,
        })
        e.preventDefault();
        setData({
            email: "",
        })
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 2000)
    }
    return (<>
        {show && <Alert setShow={setShow} />}
        <footer
            className="site-footer"
            id="contact"
            style={{ backgroundColor: "black" ,width:"100%"}}
        >
            <Container fluid >

                <Row  className="mt-5">
                    <Col lg={1}></Col>
                    <Col 
                        lg={3}
                        xs={12}
                        sm={6}
                        md={4}
                       
                        id="logofooter"
                        className="logosocial"
                        
                    >
                        <img src={dbuglobal}  alt="logo"  style={{width:"64%",marginLeft:"5px"}} />
                        {/* <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", marginLeft: "80px", color: "#ccc", fontweight: "400" }}>Follow us!</h3> */}
                        


                            {/* <span ><Button

                                sx={{
                                    marginLeft: '80px', textTransform: 'capitalize', border: '1px solid #ccc',
                                    backgroundColor: "#0e1011",
                                    bordercolor: "#ccc",
                                    borderradius: "5px",
                                    fontsize: "14px",
                                    color: "#ccc",
                                    fontweight: " 500",
                                    variant: "outlined"



                                }}
                            >Refer and Earn</Button></span> */}


                            <div  style={{ marginTop: "15px", paddingTop: "0.5rem", }}>
                                {/* <span class="mr-4 footer-icon"> */}
                                    <ul class="social-icons">
                                        <li>
                                            <a class="facebook" >
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="twitter" >
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                       
                                        <li>
                                            <a class="dribbble" >
                                                <i class="fab fa-dribbble"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="linkedin" >
                                                <i class="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="instagram" >
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>

                                {/* </span> */}
                            </div>
                            

                        
                    </Col>
                    <Col lg={2} className="d-flex mt-3 justify-content-start  flex-column flex-wrap" style={{marginLeft:""}}>
                        <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", color: "#ccc", fontweight: "400" }}>Desh Bhagat Global</h3>
                        <div style={{
                            fontSize: "14px", fontsize: "14px",
                            color: "#ccc",
                            fontweight: " 500",color:"rgba(255,255,255,.5)"
                        }} >
                            <span class="d-flex flex-wrap"><a href="https://deshbhagatglobalservices.com/">Home</a></span>
                            <span class="d-flex flex-wrap"><a href="https://deshbhagatglobalservices.com/about/">About</a></span>
                            <span class="d-flex flex-wrap"><a href="https://deshbhagatglobalservices.com/services">Services</a></span>
                            <span class="d-flex flex-wrap"><a href="/">IELTS Plus</a></span>
                            <span class="d-flex flex-wrap"><a href="https://deshbhagatglobalservices.com/latest-news/">News</a></span>
                            <span class="d-flex flex-wrap"><a href="https://deshbhagatglobalservices.com/contact/">Contact</a></span>


                        </div>
                    </Col>
                   
                    <Col lg={2} className="d-flex mt-3 justify-content-start  flex-column flex-wrap">
                        <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", color: "#ccc", fontweight: "400" }}>Legal</h3>
                        <div style={{
                            fontSize: "14px", fontsize: "14px",
                            color: "#ccc",
                            fontweight: " 500",color:"rgba(255,255,255,.5)"
                        }} >
                            <span class="d-flex flex-wrap">Terms of Service & Honor Code</span>
                            <span class="d-flex flex-wrap">Privacy Policy</span>
                            <span class="d-flex flex-wrap">Cookie Policy</span>
                            <span class="d-flex flex-wrap">Accessibility Policy</span>
                            <span class="d-flex flex-wrap">Trademark Policy</span>
                            
                        </div>
                    </Col>

                    {/* <Col lg={3} className="d-flex mt-3 justify-content-start  flex-column flex-wrap">
                        <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", color: "#ccc", fontweight: "400" }}>For Businesses</h3>
                        <div style={{
                            fontSize: "14px", fontsize: "14px",
                            color: "#ccc",
                            fontweight: " 500",color:"rgba(255,255,255,.5)"
                        }} >
                            <span class="d-flex flex-wrap">Corporate training</span>
                            <span class="d-flex flex-wrap">Partners</span>
                            <span class="d-flex flex-wrap">Digital Transformation</span>
                            
                        </div>
                    </Col> */}

                    {/* <Col lg={2} xs={12} className="d-flex mt-3 justify-content-start  flex-column flex-wrap">
                    <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", color: "#ccc", fontweight: "400" }}>Learn On the Go!</h3>
                        <div style={{
                           
                        }} >
                                <Button variant="outlined" sx={{borderBottomRightRadius:'0px',borderBottomLeftRadius:"0px",border: '1px solid #ccc', color: "#ccc"}}><AdbIcon/>Get Android App</Button>
                                <Button variant="outlined" sx={{width:"182px", borderTopLeftRadius:'0px',borderTopRightRadius:'0px', border: '1px solid #ccc',color:"#ccc",textAlign:"center"}}><AppleIcon sx={{marginRight:"20%"}}/> Get iOS App</Button>                             
                           
                            
                        </div>
                    </Col> */}
                    <Col lg={3} className="d-flex mt-3 justify-content-start  flex-column flex-wrap">
                        <h3 className="mb-2 text-capitalize text-start" style={{ fontFamily: "Roboto", fontStyle: "normal", fontSize: "16px", color: "#ccc", fontweight: "400" }}>Connect</h3>
                        <div style={{
                            fontSize: "14px", fontsize: "14px",
                            color: "#ccc",
                            fontweight: " 500",color:"rgba(255,255,255,.5)"
                        }} >
                            <span class="d-flex flex-wrap">9875944060</span>
                            <span className="no3" >contact@deshbhagatglobalservices.com</span>
                            
                        </div>
                    </Col>

                    
                </Row>
                <hr />
            </Container>
            <Container>
                <Row>
                    <Col >
                        <p  style={{color:"#ccc",textAlign:"center",marginBottom:"2%"}}>
                            Copyright &copy; 2023 All Rights Reserved.

                        </p>
                    </Col>
                
                </Row>
            </Container>
        </footer>
    </>
    );
}


export default Footer;
