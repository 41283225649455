import React, { useEffect } from "react";
import AOS from "aos";
import "./App.css";
import "aos/dist/aos.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Topbar from "./container/header/Topbar";
import About from "./container/about/About";
import VideoPage from "./container/videoSection/VideoPage";
import Courses from "./container/courses/Courses";
import Message from "./container/Messages/Message";
import PhotoGallary from "./container/gallery/PhotoGallery";
import Footer from "./container/footer/Footer";
import Slider from "./container/header/Slider";
import FullGallery from "./container/gallery/FullGallery";
import Contact from "./components/contact/Contact";
import AboutPage from "./container/about/AboutPage";
import FacultyPage from "./components/Faculty/FacultyPage";
import Event from "./components/Events/Event";
import Notify from "./components/Notification/Notify";
import CourseDetails from "./container/courses/courseDetails";
import CourseCards from "./container/courses/Courses1";
import CombinCourses from "./container/courses/CombinCourses";
import CoursesMore from "./container/courses/CoursesMore";
import Blended from './container/courses/blended'
import MainPage from "./container/MainPage";
import Footer1 from './container/footer/Footer1';
import Page from './ILETS/ieltsplus';
import Applynow from "./ILETS/Applynow";
import CourseContent from "./ILETS/CourseContent";
import HumanServices from "./ILETS/humanServices";
import Business from "./ILETS/business";
import Health from "./ILETS/health";
import Trades from "./ILETS/trades";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function App() {

  
  
  useEffect(() => { AOS.init({ duration: 900 }) }, [])

  return (

    <Router basename={process.env.PUBLIC_URL}>
      <div>
        
        <Route exact path="/">
          <Page />
        </Route>
        <Route exact path="/coursecontent">
          <CourseContent/>
        </Route>
        <Route exact path="/cat" >
          <Business />
        </Route>
        
      </div>
      <div className="App">

        
      </div>

    </Router>

  );

}

export default App;
