import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import course01 from "../../images/course01.jpeg";
import "./courses.css";
import { useEffect } from "react";
import axios from 'axios';
import { useState } from "react";
import { useHistory} from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function Courses()  {
    const [courses,setCourses]= useState([])
    const navigate = useHistory();

    const handlecourse=(course)=>{

       navigate.push("/coursecard", { state: { coursedetails:course } })
    }
    useEffect(async()=>{
      await axios({
        url:"https://web.deshbhagatonline.com/courses/coursesadded",
        method:"get",
      }).then(res=>{
        console.log(res.data.Items)
        setCourses(res.data.Items)
      })
    },[])

    return (
      <section id="courses" className="courses py-5">
        <Container>
          <Row>
            <Col xs={10} sm={6} className="mx-auto text-center course-title">
              <h1 className="text-capitalize">
                Our  <strong style={{ color: "brown" }}>Courses</strong>
              </h1>
              <p
                style={{
                  borderTop: "3px solid white",
                  width: 70,
                  marginTop: 20,
                }}
              ></p>
              <p className="text-muted" data-aos="fade-up">
                Our Courses are selected through a rigorous process and
                uniquely created .
              </p>
              <div className="latest-news">
                {/* <Marquee  onmouseover="stop()">Admission to online First Aid Course will start from 15 May.</Marquee> */}
              </div>
            </Col>
          </Row>
          
          <Row>{courses.map(
            (course, index) => {
             return(
              <Col
                xs={10}
                sm={6}
                lg={4}
                className="course-item mx-auto my-3"
                key={course + index}
                data-aos="fade-up"
              >
                <Card className="course-card">
               
                  <div className="img-container">
                    <Card.Img variant="top" src={course01} className="course-img" />
                    <span className="course-item-icon">
                      <i className="fas fa-star"></i>
                    </span>
                  </div>
                  
                  <Card.Body>
                  <NavLink to= {{pathname: "/coursecard", state: {coursedetails:course }}} >
                    <Card.Title >{course.title}</Card.Title>
                    <Card.Text>
                      {course.description}
                    </Card.Text>
                    </NavLink>
                    <div className="text-center">
                      <Button variant="primary text-center">Register</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
             ) 
             }
          )}</Row>
          
          <Row>
            <Col className="mx-auto my-3" data-aos="zoom-in">
              <div className="text-center">
                <Button variant="danger">View More</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  
}

export default Courses;
