import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, Box, Typography } from '@mui/material'

import { NavLink } from "react-router-dom"
import axios from 'axios';
import bedside from '../../images/bedside.jpg';
import dmlt from '../../images/dmlt.jpg';
import elemantry from '../../images/elemantry.jpg';
import library from '../../images/library.jpg';
import nanny from '../../images/nanny.jpg';
import nanny1 from '../../images/nanny1.jpg';
import ot from '../../images/ot.jpg';
import pharma from '../../images/pharma.jpg';
import physio from '../../images/physio.jpg';
import Radio from '../../images/Radio.jpg';
import yoga from '../../images/yoga.jpg';

const CourseCards = () => {
    const arr = [
    { img: dmlt, name: 'Diploma of Medical Laboratory Technology (DMLT)', rating: '4.5 (26 ratings)', price: '3600', aprice: '4500' },

   
    { img: physio, name: 'Diploma in Physiotherapy', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
   
    { img: yoga, name: 'Diploma in Yoga and Naturopathy', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
    { img: bedside, name: 'Diploma in Bed Side Assistant', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
  
    { img: nanny, name: 'Certificate Course in Nanny Care', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
    { img: library, name: 'Bachelor of Library and Information Science (B. Lib)', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
    { img: elemantry, name: 'Diploma in Elementary Education', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' },
    ]
    const [courseArray, setCourseArray] = useState([])
    const get_courses = async () => {
        try {
            const results = await axios({
                url: "https://web.deshbhagatonline.com/courses/coursesadded",
                
                method: "GET"
            })
            console.log(results.data.Items)
            setCourseArray(results.data.Items)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        get_courses()
    }, [])
    // const [arr, setarr] = useState([{ img: sql, name: 'SQL-MySQL for Data Analytics', rating: '4.5 (26 ratings)', price: '3600', aprice: '4500' },
    // { img: power, name: 'Power BI', rating: '4.5 (26 ratings)', price: '3600', aprice: '4500' },
    // { img: buis, name: 'Buisness Intelligence(Part 1)- Introduction to Statistics', rating: '4.5 (26 ratings)', price: '3800', aprice: '4500' },
    // { img: Python, name: 'Machine Learning with python', rating: '4.5 (26 ratings)', price: '5999', aprice: '6600' }])
    return (
        <Grid container xs={12} lg={12} sm={12} md={12} sx={{ backgroundColor: '#f8f9fb', marginTop: '40px' }}>
            <Grid lg={1} sm={0.5} md={0.5} />
            <Grid item container xs={12} lg={10} sm={11} md={11} marginTop='50px' marginBottom={'50px'}>
                <Grid item xs={12} lg={9.8} sm={9.5} md={9.6} justifyContent='space-between' marginTop={'20px'}>
                    <Typography sx={{ fontSize: '1.65rem', fontWeight: '700', color: '#283034', fontFamily: 'Muli,sans-serif', paddingLeft: "10px" }}> Online Courses</Typography>
                </Grid>
                <Grid item xs={10} lg={2} sm={2} md={2} sx={{ textAlign: "center", marginTop: '20px', marginLeft: '15px' }} >
                    {/* <NavLink to={'/Download'} style={{ textDecoration: 'none', display: 'flex' }}>
                        <Button variant='contained' sx={{ backgroundColor: '#ff1f59', border: '2 px solid #ff1f59', '&:hover': { backgroundColor: 'white', border: '2px solid black', color: 'black' }, fontSize: '15px', padding: '10px', height: '50px', color: 'white' }}>Download Brochure</Button>
                    </NavLink> */}
                </Grid>
                {courseArray.map((item) => {
                    console.log(item.price)

                    return (
                        <Grid item xs={12} lg={3} sm={6} md={6} marginTop='40px' padding={"12px"}>
                            <NavLink to= {{pathname: "/coursecard", state: {coursedetails:item }}} >
                                

                                <Box sx={{ height: '430px', width: '100%', border: '1px solid #ddd', boxShadow: '0 50px 100px -20px rgb(50 50 93 / 25%)', textAlign: 'left', borderRadius: '10px', '&:hover': { boxShadow: '0 0 0 0' } }}>
                                    <img src={`https://dbucourse.s3.amazonaws.com/${item.courseId}.png`} style={{ width: '100%', height: '230px', borderRadius: '5px' }}  alt="" />
                                    <Box>
                                        <Grid marginTop={'8px'} marginLeft='5px' display='flex'>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Languages:</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>English</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Hindi</Box>
                                        </Grid>

                                        <Grid marginTop={'8px'} marginLeft='10px'>
                                            <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>{item.title}</Typography>
                                            <Typography sx={{ fontSize: '1.05rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>{item.duration}</Typography>
                                            <div style={{ display: 'flex' }}>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif',  }}> {item.price}</Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif', marginLeft: '10px' }}></Typography>
                                            </div>
                                        </Grid>
                                    </Box>
                                    {/* <Button sx={{ color: 'blue' }}> &#62;&#62;go to Link</Button> */}
                                </Box>
                            </NavLink>
                        </Grid>
                    )
                })}
                {arr.map((item, index) => {
              return (
                                        <Grid item xs={12} lg={3} sm={6} md={6} marginTop='40px' padding={"12px"}>
                       
                                

                                <Box sx={{ height: '430px', width: '100%', border: '1px solid #ddd', boxShadow: '0 50px 100px -20px rgb(50 50 93 / 25%)', textAlign: 'left', borderRadius: '10px', '&:hover': { boxShadow: '0 0 0 0' } }}>
                                    <img src={item.img} style={{ width: '100%', height: '230px', borderRadius: '5px' }}  alt="" />
                                    <Box>
                                        <Grid marginTop={'8px'} marginLeft='5px' display='flex'>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Languages:</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>English</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Hindi</Box>
                                        </Grid>

                                        <Grid marginTop={'8px'} marginLeft='10px'>
                                            <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>{item.name}</Typography>
                                            <Typography sx={{ fontSize: '1.05rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>{item.duration}</Typography>
                                            <div style={{ display: 'flex' }}>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif',  }}>Admission Open</Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif', marginLeft: '10px' }}></Typography>
                                            </div>
                                        </Grid>
                                    </Box>
                                    {/* <Button sx={{ color: 'blue' }}> &#62;&#62;go to Link</Button> */}
                                </Box>
                           
                        </Grid>               
                        );
            })}
            </Grid>

        </Grid>


    )
}

export default CourseCards;