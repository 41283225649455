import React, { useState } from 'react';
import { Button, Modal, Box, Typography, Grid, TextField, Select } from '@mui/material';
import axios from 'axios';
import { Form } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import '../ILETS/re.css';
import Swal from "sweetalert2";
 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '14px'
};

const Register = (props) => {
    console.log(props)
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        msg: ""
    })
    const [open1, setOpen1] = React.useState(false);
    
    const submit = async (e) => {
        props.Close()
        axios({
            method: "post",
            url: "https://web.deshbhagatonline.com/mailer/crestbellforielts",
            data: data,
        }).then(res=>{
            if(res.status=="200"){
                
                Swal.fire({
                    icon: "success",
                    text: "Success",
                  });
                //   })
        }})
        e.preventDefault();
        setData({
            name: "",
            email: "",
            phone: "",
            msg: "",
        })
    }
    console.log(data)
    return (

        <div >
            <Box className="res1" sx={style}>

                <Grid  sx={{ padding: "25px", mb: '2%', borderRadius: '14px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '28px', fontWeight: 700, }}>How can I help you ?</Typography>
                        <CloseIcon onClick={props.Close} sx={{ marginTop: '2%', color: '#21252954', fontWeight: '300', cursor: 'pointer' }} />
                    </div>

                    <Grid item >
                        <Form onSubmit={submit}>
                            <TextField value={data.name} required sx={{ mb: '16px', mt: '30px' }} inputProps={{ style: { height: '12px' } }} onChange={e =>
                                setData({ ...data, name: e.target.value })
                            } id="outlined-basic" placeholder="Name*" variant="outlined" fullWidth />


                            <TextField value={data.phone} required sx={{ mb: '16px' }} inputProps={{ style: { height: '12px' } }} type="number" onChange={e =>
                                setData({ ...data, phone: e.target.value })
                            } id="outlined-basic" placeholder="Phone no.*" variant="outlined" fullWidth />


                            <TextField value={data.email} required sx={{ mb: '16px' }} inputProps={{ style: { height: '12px' } }} type='email' onChange={e =>
                                setData({ ...data, email: e.target.value })
                            } id="outlined-basic" placeholder="Email*" variant="outlined" fullWidth />


                            <TextField value={data.msg} required sx={{ mb: '16px' }} inputProps={{ style: { height: '12px' } }} type='text' onChange={e =>
                               setData({ ...data, msg: e.target.value })
                            } id="outlined-basic" placeholder="Type the course name you want to enroll in*" variant="outlined" fullWidth />
                            
                            <Button type="submit" variant='contained' sx={{ backgroundColor: '#273773', padding: '10px', fontSize: '17px', fontWeight: 500, marginTop: '3%', textTransform: 'capitalize', '&:hover': { bgcolor: '#273773' } }} fullWidth
                                onClick={submit.required}
                            >Submit</Button>
                        </Form>
                    </Grid>

                </Grid>
            </Box>

        </div>
    )
}
export default Register;
