import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  ModalBody,
} from "react-bootstrap";
import logo from "../../images/clogo.png";
import "./header.css";
import { Link } from "react-router-dom";
import {Grid} from '@mui/material';
import logonew from '../../images/logonew.jpg';


 function Topbar() {
    
    return (
      <Grid item xs={12} lg={12} md={12} sm={12}>
         <Navbar
        expand="xl"
        
        style={{ backgroundColor: "#fff",paddingRight:'1rem',paddingLeft:'1rem',flexWrap:'-moz-initial3' }}
      >
        <Navbar.Brand href="https://www.deshbhagatonline.com/" style={{marginRight:'0%'}}>
          <img src={logonew}  height="100px" alt="logo" width="100%" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{fontSize:'0.9rem'}} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Link to="/">
              <Nav.Link href="#home" style={{fontSize:'1.4rem'}}>Home</Nav.Link>
            </Link>
            
             <NavDropdown  style={{fontSize:"1.4rem",marginTop:'1%'}} title="Courses" id="administration-dropdown">
              <Link to="/courses">
                <NavDropdown.Item href="/courses" >Online Courses</NavDropdown.Item>
              </Link>
              <Link to="/blended">
                <NavDropdown.Item href="/blended">Blended</NavDropdown.Item>
              </Link> 
              <Link >
                <NavDropdown.Item >Training Courses</NavDropdown.Item>
              </Link> 
              <Link >
                <NavDropdown.Item >IELTS Plus</NavDropdown.Item>
              </Link> 
            </NavDropdown> 
            
            
            <Link to="/about">
              <Nav.Link href="/about" style={{fontSize:'1.4rem'}}>About</Nav.Link>
            </Link>
            <Link to="/contact">
              <Nav.Link href="/contact" style={{fontSize:'1.4rem'}}>Contact</Nav.Link>
            </Link>
          </Nav>

          {/* <Auth/> */}
          <Form >
            {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
            <Button className="buto" style={{backgroundColor:'rgb(1 69 106)'}}><a href="https://lms.deshbhagatonline.com" style={{color:"white",}}>Sign In</a></Button>
            
          </Form>
        </Navbar.Collapse>

      </Navbar>
      </Grid>
     
      
    );
  
}

export default Topbar;
