import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import aboutPic from "./slide-01.jpg";
import Topbar from "../header/Topbar";

function AboutPage() {

  return (
    
    <section>
      
      <Container
        style={{ backgroundColor: "brown", color: "#fff" }}
        className="text-center py-5 aboutpage"
        fluid
      >
        <Row>
          <Col>
            <h1>About</h1>
            <p>
              Home / <span>About</span>
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        {/* <Row style={{ backgroundColor: "#fff" }}>
          <Col className="mx-auto px-lg-5 text-left mt-5 mb-5">
            <h1>Overview</h1>
            <div style={{ borderTop: "4px solid green", width: 50 }}></div>
            <p className="mt-3">
              Desh Bhagat University, Mandi Gobindgarh came into existence under Punjab Govt’s Desh Bhagat University Act. The university derives its spirit of foundation from freedom fighter Sr. Lal Singh Ji; who fought for the independence of India and in 1972 his efforts of social work & his services in the freedom struggle were recognized by awarding him with “Tamra Patra” by Govt. of India, hence the name Desh Bhagat was contemplated.We are the 1st State Pvt. University in Punjab to have both 12B status & NAAC.



            </p>
            <p className="mt-3">
              Desh Bhagat University is on the cutting edge of education in several areas like Engineering, Management, Hotel Management, Hospitality and Tourism, Computer Sciences, Ayurveda, Nursing, Education, Commerce, Media, Animation, Airlines, Law, Agriculture Sciences, Performing Arts, Languages, Social Sciences, Applied Sciences, Pharmacy, Dental Sciences, Paramedical Sciences, Art & Craft and Fashion Technology.

            </p>
          </Col>
        </Row> */}

        <Row style={{ backgroundColor: "#fff" }}>
        <Col
            xs={10}
            lg={4}
            md={6}
            className="mx-auto px-lg-5 my-5 align-self-center"
            data-aos="fade-in"
          >
            <div className="about-img_container">
              <Image
                src={"https://deshbhagatuniversity.in/wp-content/uploads/2022/10/why-join-dbu.jpg"}
                fluid
                style={{ border: "1px solid brown", padding: "5px" }}
              />
            </div>
          </Col>
          <Col xs={10} lg={8} md={6} className="mx-auto px-lg-5 my-5">
            <h1 className="text-capitalize">
              <strong className="banner-title">Overview</strong>
            </h1>
            <div style={{ borderTop: "4px solid green", width: 50 }}></div>
            <p
              className="my-4 text-muted w-75 about-text"
              data-aos="fade-right"
            >Desh Bhagat University, Mandi Gobindgarh came into existence under Punjab Govt’s Desh Bhagat University Act.University in Punjab to have both 12B status & NAAC.
    </p>
              <p  className="my-4 text-muted w-75 about-text"
              data-aos="fade-right">
The university derives its spirit of foundation from freedom fighter Sr. Lal Singh Ji; who fought for the independence of India and in 1972 his efforts of social work & his services in the freedom struggle were recognized by awarding him with “Tamra Patra” by Govt. of India, hence the name Desh Bhagat was contemplated.              </p>
              <p className="my-4 text-muted w-75 about-text"
              data-aos="fade-right">
              Desh Bhagat University is on the cutting edge of education in several areas like Engineering, Management, Hotel Management, Hospitality and Tourism, Computer Sciences, Ayurveda, Nursing, Education, Commerce, Media, Animation, Airlines, Law, Agriculture Sciences, Performing Arts, Languages, Social Sciences, Applied Sciences, Pharmacy, Dental Sciences, Paramedical Sciences, Art & Craft and Fashion Technology.
              </p>
              
          </Col>
          
        </Row>

        <Row style={{ backgroundColor: "#E0E0E0", color: "white" }}>
          <Col xs={10} lg={8} md={6} className="mx-auto px-lg-5 my-5">
            <h1 className="text-capitalize">
              <strong className="banner-title">Mission</strong>
            </h1>
            <div style={{ borderTop: "4px solid green", width: 50 }}></div>
            <p
              className="my-4 text-muted w-75 about-text"
              data-aos="fade-right"
            >To emerge as the country’s leading value based educational and professional hub to have transformative impact on society by practicing innovation, patronizing research and shaping global entrepreneurs and leaders
    </p>
              <p  className="my-4 text-muted w-75 about-text"
              data-aos="fade-right">
              Providing a strong foundation of values and principles for developing ethical decision making and moralityamongst students for sustainable growth & development.
              </p>
              <p className="my-4 text-muted w-75 about-text"
              data-aos="fade-right">
              Transforming all sections of society with relevant education, contemporary research, expert consultancy and support for fostering social parity & justice.
              </p>
              <p  className="my-4 text-muted w-75 about-text"
              data-aos="fade-right">
              Collaboration with industry for development of professional skills and entrepreneurial mind to make students industry ready.
              </p>
          </Col>
          <Col
            xs={10}
            lg={4}
            md={6}
            className="mx-auto px-lg-5 my-5 align-self-center"
            data-aos="fade-in"
          >
            <div className="about-img_container">
              <Image
                src={aboutPic}
                fluid
                style={{ border: "1px solid brown", padding: "5px" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutPage;
