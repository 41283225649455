import React from 'react';
import { Grid,Box, Typography, Divider } from '@mui/material';
import './footer.css';

const Duration = () => {
  window.scrollTo(0, 0)
  return (
    <Grid container sx={{bgcolor:'#f8fbff'}}>
    <Grid item lg={2}></Grid>
    <Grid item lg={8} xs={12} md={12} sm={12} className='du4'>
      <Box className="du" sx={{border:'1px solid #e9f2ff',boxShadow:'0px 0px 10px rgba(17,121,239,.001)',borderRadius:'4px',padding:'21px 0',bgcolor:'#fff',marginTop:'-45px'}}>
         <div className='du1' style={{display:'flex',justifyContent:'space-around'}}>
         <div >
             <Typography sx={{fontFamily:'Roboto',fontSize:'18px',fontWeight:'normal',color:'#51565e',lineHeight:'16px',textAlign:'center'}}>
            Course starts On
            </Typography>
            <Typography sx={{fontFamily:'Roboto',fontSize:'20px',fontWeight:'700',color:'#51565e',lineHeight:'19px',marginTop:'10px',textAlign:'center'}}>
            30 Sept, 2023
            </Typography>
         </div>
         <Divider orientation='vertical' flexItem/>
         <div className='du2'>
             <Typography sx={{fontFamily:'Roboto',fontSize:'18px',fontWeight:'normal',color:'#51565e',lineHeight:'16px',textAlign:'center'}}>
             Duration (Months)
            </Typography>
            <Typography sx={{fontFamily:'Roboto',fontSize:'20px',fontWeight:'700',color:'#51565e',lineHeight:'19px',marginTop:'10px',textAlign:'center'}}>
            1 / 3 / 6 / 12 
            </Typography>
         </div>
         <Divider orientation='vertical' flexItem/>
         <div className='du3'>
             <Typography sx={{fontFamily:'Roboto',fontSize:'18px',fontWeight:'normal',color:'#51565e',lineHeight:'16px',textAlign:'center'}}>
             Learning Format
            </Typography>
            <Typography sx={{fontFamily:'Roboto',fontSize:'20px',fontWeight:'700',color:'#51565e',lineHeight:'19px',marginTop:'10px',textAlign:'center'}}>
            Online
            </Typography>
         </div>
         </div>
      </Box>
    </Grid>
    <Grid item lg={2}></Grid>
    </Grid>
  )
}

export default Duration
